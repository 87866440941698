// use in pair with chkboxes controller
// features:
// - hide checkboxes if there are no actions to perform
// - set colspan on checkboxes preview cell (from chkboxes controller) based on columns count
// - prepare "choose one" table
//   - remove multi actions
// - prepare "choose many" table
//   - disable checkboxes for unqualified records

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this._prepareChooseOneTable()
    this._prepareChooseManyTable()

    if (!this.multiActionBtns.childElementCount) {
      this._hideChkboxes()
    }
    // else disable chkboxes without actions
    this._setColspan()
  }

  // tabela do wyboru jednego rekordu tabeli
  _prepareChooseOneTable () {
    const chooseOneBtns = this.element
      .querySelectorAll('.choose-one')
    if (!chooseOneBtns.length) return

    // remove multi action btns to hide checkboxes
    this.multiActionBtns?.replaceChildren()
  }

  // tabela do wyboru wielu rekordów tabeli
  _prepareChooseManyTable () {
    const chooseManyBtns = this.element
      .querySelectorAll('.choose-many')
    if (!chooseManyBtns.length) return

    chooseManyBtns.forEach(btn => {
      if (!btn.childElementCount) { // dezaktywacja checkboxa
        const chkbox = btn.closest('tr').querySelector('input[type=checkbox]')
        chkbox.disabled = true
      }
    })
  }

  get multiActionBtns () {
    return this.element
      .querySelector('tfoot tr th:last-of-type .action-btns')
  }

  _hideChkboxes () {
    const selPrefix = 'tr > th:first-of-type'

    this.element.querySelectorAll(`${selPrefix} .custom-control-label`)
      .forEach(label => label.removeAttribute('for'));

    ['custom-control', 'custom-control-label'].forEach(n =>
      this.element.querySelectorAll(`${selPrefix} .${n}`)
        .forEach(el => el.classList.remove(n))
    )
  }

  _setColspan () {
    const colsCount = this.element.querySelectorAll('thead tr:first-of-type th').length
    this.element
      .querySelector('[data-chkboxes-target=preview]')
      ?.setAttribute('colspan', colsCount - 1)
  }
}
