import { Controller } from '@hotwired/stimulus'

// calculate `inputTarget` values with `operatorValue`;
// result stored in `outputTarget`
//
// example:
// <div data-controller="calc">
//   <input type="number" data-calc-target="input" data-action="calc#calc">
//   <input type="number" data-calc-target="input" data-action="calc#calc">
//   <input type="number" data-calc-target="output">
// </div>
export default class extends Controller {
  static targets = [
    'input',
    'output'
  ]

  static values = {
    operator: { type: String, default: '+' },
    cast: { type: String, default: 'number'}
  }

  calc () {
    if (this.inputTargets.some(this._emptyValue)) return
    const values = this.inputTargets
      .map(this._castElValue.bind(this))
    this.outputTarget.value = this._reduce(values)
  }

  _emptyValue(el) {
    return el.value.trim() === ""
  }

  _castElValue(el) {
    const val = el.value
    switch (this.castValue) {
      case 'number': return new Number(val)
      default: return val
    }
  }

  _reduce(values) {
    return values.reduce((acc, v) => {
      switch (this.operatorValue) {
        case '+': return acc + v
        default: return v // return last element
      }
    })
  }
}
