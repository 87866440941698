import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  run ({ formData }) {
    // iterate unique keys
    [...new Set(formData.keys())].forEach(key => {
      const values = formData.getAll(key)
      const nonEmptyValues = values.filter(v => v !== '')
      if (values.length !== nonEmptyValues.length) {
        // delete all values with key
        formData.delete(key)
        // append only non empty value
        nonEmptyValues.forEach(v => formData.append(key, v))
      }
    })
  }
}
