import { Controller } from "@hotwired/stimulus"

// Correlated Inputs
// Updates toTarget on fromTarget change.
// toTarget.value looked up via mappingValue object.
// ex.
// <div
//    data-controller="forms--corr-inputs"
//    data-forms-corr-inputs-mapping-value="{a: 1, b: 2}">
//   <input data-forms--corr-inputs-target="from"
//      data-action="data-forms--corr-inputs#update"></input>
//   <input data-forms--corr-inputs-target="to"></input>
// </div>
export default class extends Controller {
  static targets = ["from", "to"]

  static values = {
    mapping: Object
  }

  update() {
    this.toTarget.value = this.mappingValue[this.fromTarget.value]
  }
}
