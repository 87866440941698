// features:
// - display selected checkbox values into `previewTarget` element
// - toggle all checkboxes
// OPTTODO: sync url searchParams with selected ids; set initial checked state of toggleAll button
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    ids: Array
  }

  static targets = [
    "preview", // element for displaying checkboxes values
    "hideable", // container element for preview and actions; hidden if idsValue is empty
    "checkbox", // targets for toggleAll
  ]

  connect() {
    this._toggleHideable()
    this._displayValues()
  }

  toggle(event) {
    const value = event.target.value
    if (event.target.checked) {
      this._addIds(value)
    } else {
      this._removeIds(value)
    }
  }

  toggleAll(event) {
    const values = this.checkboxTargets
      .filter(checkbox => !checkbox.disabled)
      .map(checkbox => {
        checkbox.checked = event.target.checked
        return checkbox.value
      })
    if (event.target.checked) {
      this._addIds(...values)
    } else {
      this._removeIds(...values)
    }
  }

  _addIds(...ids) {
    this.idsValue = this.idsValue.concat(
      ids.filter(id => !this.idsValue.includes(id))
    )
    // console.log(this.idsValue)
    this._displayValues();
    this._toggleHideable();
  }

  _removeIds(...ids) {
    this.idsValue = this.idsValue.filter(id => !ids.includes(id))
    // console.log(this.idsValue)
    this._displayValues();
    this._toggleHideable();
  }

  _toggleHideable() {
    if (this.hasHideableTarget) {
      this.hideableTargets.forEach(el => el.classList.toggle('d-none', !this.idsValue.length))
    }
  }

  // display ids in previewTarget as hidden checkboxes and text
  _displayValues() {
    if (this.previewTarget) {
      this.previewTarget.innerText = ''

      // display ids as hidden checkboxes
      // useful for synced-params controller
      Array.from(this.idsValue).forEach(id => {
        const checkbox = document.createElement("input")
        checkbox.type = 'checkbox'
        checkbox.checked = checkbox.hidden = true
        checkbox.value = id
        this.previewTarget.append(checkbox)
      })

      // display ids as text
      this.previewTarget.append(
        document.createTextNode(
          Array.from(this.idsValue).join(', ')
        )
      )
    }
  }
}
