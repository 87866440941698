import { Controller } from '@hotwired/stimulus'

// example:
// <select data-controller='select2'>
//    <option value='0'>Dyscyplina</option>
//  </select>
export default class extends Controller {
  connect () {
    this.element.select2 = this
    $(this.element).on('change', () => {
      this.element.dispatchEvent(new Event('select2:change', { bubbles: true }))
    })
    this.init()
  }

  disconnect () {
    $(this.element).off('change')
    $(this.element).select2('destroy')
  }

  setValue (value) {
    $(this.element).val(value).trigger('change').trigger('select2:select')
  }

  init () {
    this._removeRemains() // handle back btn
    $(this.element).select2()
    $(this.element).select2({ width: 'auto' })
  }

  _removeRemains () {
    const nextSibling = this.element.nextSibling
    if (nextSibling?.classList?.contains('select2')) {
      nextSibling.remove()
    }
  }
}
