import { Controller } from "@hotwired/stimulus"

// use with turbo streams, where `autofocus` attribute does not have effect
export default class extends Controller {
  connect () {
    // set focus on controller element
    this.element.focus()
    // select all text in the input, to override exiisting value when typing
    this.element.select()
  }
}
