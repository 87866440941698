import { Controller } from '@hotwired/stimulus'

// Special characters picker
// ex.
// form data-controller="special-chars"
//   input
//   = render "modals--special_chars"
//     button data-action="modal--basic#open" = t("v.special_chars")
export default class extends Controller {
  connect () {
    this.element.addEventListener('focusin', this._trackFocusedInput.bind(this))
  }

  disconnect () {
    this.element.removeEventListener('focusin', this._trackFocusedInput.bind(this))
  }

  insert (e) {
    e.preventDefault()
    if (this.lastFocusedInput) {
      const input = this.lastFocusedInput
      const caretPos = input.selectionStart
      const selectedChar = e.currentTarget.textContent

      const front = input.value.substring(0, caretPos)
      const back = input.value.substring(input.selectionEnd, input.value.length)

      input.value = `${front}${selectedChar}${back}`
      const newCaretPos = caretPos + selectedChar.length
      input.selectionEnd = input.selectionStart = newCaretPos
      input.dispatchEvent(new Event('input'))
      setTimeout(() => input.focus())
    }
  }

  _trackFocusedInput(e) {
    if (e.target.matches('input[type="text"]')) {
      this.lastFocusedInput = e.target
    }
  }
}
