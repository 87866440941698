import { Controller } from "@hotwired/stimulus"
import Modal from "lib/bootstrap/modal"

// Basic modal
// ex.
// div data-controller="modals--basic"
//   = render "modals--basic", title: title
//     | CONTENT
//   button data-action="modal--basic#open" Open
export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.element[this.identifier] = this // used by controllers: record-fields-from-hotkeys
    this.modal = new Modal(this.modalTarget, { focus: true })
    if (!Number.isInteger(window.countOpenModals)) window.countOpenModals = 0
    this.modalTarget.addEventListener('show.bs.modal', () => this._modalShow());
    this.modalTarget.addEventListener('hidden.bs.modal', () => this._modalHidden());
  }

  disconnect() {
    this.modalTarget.removeEventListener('show.bs.modal', () => this._modalShow());
    this.modalTarget.removeEventListener('hidden.bs.modal', () => this._modalHidden());
    this.modal.dispose()
  }

  _modalShow() {
    window.countOpenModals += 1
    this.modalTarget.dispatchEvent(new Event(`${this.identifier}:show`, { "bubbles": true }))
  }

  _modalHidden() {
    window.countOpenModals -= 1
    this.modalTarget.dispatchEvent(new Event(`${this.identifier}:hidden`, { "bubbles": true }))
  }

  close() {
    this.modal.hide()
  }

  open() {
    this.modal.show()
    // move backdrop after modal
    this.modalTarget.after(document.querySelector(".modal-backdrop"));
  }
}
