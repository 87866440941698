import { Controller } from "@hotwired/stimulus"

// Zapisuje dodatkową wartość po wybraniu opcji selecta z `option[data-id]` w targecie `input`
// <div class="form-group select" data-controller="select-additional-option">
//   <input data-select-additional-option-target="input">
//   <select data-action="select-additional-option#sync">
//     <option value="2" data-id="358544502">Sygnatura</option>
//     <option value="1" data-id="770962277">Data</option>
//   </select>
// </div>
export default class extends Controller {
  static targets = [
    'input'
  ]

  sync(event) {
    this.inputTarget.value = event.currentTarget.querySelector("option:checked").dataset.id
  }
}
