// features:
// - show collapsed form rows
//   - with filled values
//   - marked as default with css class (in case of empty form)
// - toggle action buttons
// used in pair with collapsed controller (ie. from BS https://getbootstrap.com/docs/5.2/components/collapse/),
//   depends on 'show' class on collapsedTarget on connect
//  and 'aria-expanded' attribute on toggleBtnTarget
import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use"

export default class extends Controller {
  static targets = [
    "shown", // container element for shown filter rows
    "collapsed",  // container element for collapsed filter rows
    "actions",  // default actions, ie: "sending form"(, "clearing form")
    "actionsOnResults", // actions on result set; hidden if no filled rows
    "toggleBtn"
  ]

  connect() {
    const collapsedRows = this.collapsedTarget.children

    for (const rowType of ["filled", "default"]) {
      let rows = this._filterRows(collapsedRows, rowType)
      // console.log(rowType, rows)
      if (rows.length) {
       this._showRows(rows)
       break
      }
    }

    // filtry mogą być rozwinięte przy użyciu przycisku wstecz
    useMutation(this, { element: this.toggleBtnTarget, attributeFilter: ["aria-expanded"] })
    this._initBtnState()
  }

  mutate() {
    this._toggleActions(this.toggleBtnTarget.getAttribute("aria-expanded") == "true")
  }

  _filterRows(rows, type) {
    const arr = Array.from(rows)
    switch (type) {
      case "default":
        return arr.filter(r => r.classList.contains('default'));
      case "filled":
        return arr.filter((r) => {
          const inputs = r.querySelectorAll(`
            input:not([type="hidden"]):not([type="checkbox"]),
            input[type="checkbox"]:checked,
            select
          `)
          return Array.from(inputs).some(input => !!input.value.length)
        })
      default: return []
    }
  }

  _showRows(rows) {
    rows.forEach((row) => {
      this.shownTarget.append(row)
    })
  }

  _toggleActions(expanded) {
    const showActions = this.shownTarget.hasChildNodes()
      || expanded
    this.actionsTarget.classList.toggle("d-none", !showActions)

    if (this.hasActionOnResultsTarget) {
      const showActionsOnResults = !!this._filterRows(this.shownTarget.children, "filled").length
      this.actionsOnResultsTarget.classList.toggle("d-none", !showActionsOnResults)
    }
  }

  // klasa "show" ustawiana przez wtyczkę BS collapse
  _initBtnState() {
    const btn = this.toggleBtnTarget
    btn.setAttribute("aria-expanded", this.collapsedTarget.classList.contains("show"))
    if (!this.collapsedTarget.children.length) {
      btn.classList.add("d-none")
    }
  }
}
