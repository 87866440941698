import { Controller } from '@hotwired/stimulus'

// set Turbo-Frame-Referrer header, to track referrer from frame request
// used for example to generate proper `back_to_index` button
// usage:
// = turbo_frame_tag "frame1", data: { \
//   controller: "turbo-frame-referrer",
//   action: "turbo:frame-render->turbo-frame-referrer#render turbo:before-fetch-request->turbo-frame-referrer#setHeader" \
// }
export default class extends Controller {
  render () {
    this.src = this.element.src
  }

  setHeader (e) {
    const headers = e.detail.fetchOptions.headers
    headers['Turbo-Frame-Referrer'] = this.src
  }
}
