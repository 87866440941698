import { Controller } from "@hotwired/stimulus"

// example:
// <div data-controller="form-element-toggler"
//      data-form-element-toggler-attribute-toggler-value="value"
//      data-form-element-toggler-hide-value="true"
//      data-form-element-toggler-hide-closest-value=".form-group">
//   <select data-form-element-toggler-target="toggle" data-action="form-element-toggler#toggle">
//     <option value="contain">contain</option>
//     <option value="isset">isset</option>
//   </select>
//   <div class="form-group">
//     <input data-form-element-toggler-target="formElement" data-form-element-toggler-toggle-attribute-value="isset not_isset" type="text">
//   </div>
// </div>

export default class extends Controller {
  static targets = [
    "toggle", // element with attribute to toggle
    "formElement" // element with attribute `data-form-element-toggler-toggle-attribute-value`
  ]
  static values = {
    attributeToggler: { type: String, default: "value" },
    inversion: Boolean,
    disable: { type: Boolean, default: true }, // disable on toggle
    hide: { type: Boolean, default: false }, // hide on toggle
    hideClosest: String, // hide closest element by selector np: `.form-group`
  }

  connect() {
    this.toggle()
  }

  toggle(event) {
    const toggleElement = (event || {}).currentTarget ||
      this.toggleTargets.reduce(function (el, currentEl) { return currentEl.checked ? currentEl : el; })
    this.formElementTargets.forEach(formElement => {
      let bool = this.paramComparison(toggleElement, formElement)
      if (this.inversionValue) bool = !bool
      if (this.hideValue) {
        this.hide(formElement, bool, this.hideClosestValue)
      }
      if (this.disableValue) {
        formElement.disabled = bool
      }
    })
  }

  hide(formElement, bool, hideClosest) {
    if (hideClosest) {
      formElement.closest(hideClosest).classList.toggle("d-none", bool)
    } else {
      formElement.classList.toggle("d-none", bool)
    }
  }

  paramComparison(toggleElement, formElement, split_by = " ") {
    switch (this.attributeTogglerValue) {
      case "value":
        return formElement.dataset.formElementTogglerToggleAttributeValue.split(split_by).includes(toggleElement.value)
      case "checked":
        return toggleElement.checked === (formElement.dataset.formElementTogglerToggleAttributeValue === "true")
      default:
        return formElement.dataset.formElementTogglerToggleAttributeValue.split(split_by).includes(toggleElement.getAttribute(this.attributeTogglerValue))
    }
  }
}
